import React from "react";
import Alert from "components/Alert";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Col, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-toastify";
import { get } from "lodash";
import Button from "components/Button";
import WithdrawalReasonSelect from "components/transferWidget/WithdrawalReasonSelect";
import { formatAmount, formatCurrency } from "utils/number";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { disburseUserAccount, liquidateAccount } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");

class LiquidationForm extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    userId: PropTypes.string,
    client: PropTypes.object,
    liquidateAccount: PropTypes.func,
    disburseUserAccount: PropTypes.func,
    isUpdating: PropTypes.bool,
    account: PropTypes.shape({
      id: PropTypes.string,
      assetBalance: PropTypes.number,
    }),
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      showApprovalPopover: false,
    };
  }

  _disburseAccount = (values) => {
    if (values.isFullDisbursement) {
      this.props
        .liquidateAccount(this.props.client, {
          userId: this.props.userId,
          accountId: this.props.account.id,
          closeAccount: values.closeAccount,
          reason: values.closeAccount ? values.reason : "",
        })
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully submitted full disbursement request.");
            document.body.click();
            this.props.onSuccess();
          } else {
            toast.error(this.props.error);
          }
        });
    } else {
      this.props
        .disburseUserAccount(this.props.client, {
          userId: this.props.userId,
          accountId: this.props.account.id,
          amount: values.amount,
          reason: values.reason,
        })
        .then(() => {
          if (!this.props.error) {
            toast.success(
              "Successfully submitted partial disbursement request."
            );
            document.body.click();
            this.props.onSuccess();
          } else {
            toast.error(this.props.error);
          }
        });
    }
  };

  render() {
    const accountBalance = get(this.props.account, "assetBalance", 0);

    const schema = yup.object({
      isFullDisbursement: yup
        .boolean()
        .label("Is Full Disbursement")
        .required(),
      reason: yup.string().when("isFullDisbursement", {
        is: false,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      amount: yup
        .number()
        .when("isFullDisbursement", {
          is: false,
          then: yup
            .number()
            .required()
            .test(
              "amount",
              `Amount must be greater than 0`,
              (value) => value > 0
            ),
          otherwise: yup.number().nullable(),
        })
        .test(
          "amount",
          `Amount must not be greater than account balance (${formatCurrency(
            accountBalance
          )})`,
          (value) => value < accountBalance
        ),
    });
    return (
      <div id={"account-liquidation"}>
        <div className="liquidation-form">
          <Formik
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={() => this.setState({ showApprovalPopover: true })}
            enableReinitialize={true}
            initialValues={{
              isFullDisbursement: true,
              reason: "",
              amount: null,
              closeAccount: false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              handleBlur,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Form.Row>
                  <Form.Group controlId="formIsFullDisbursement">
                    <Form.Label>Will this be a full disbursement?</Form.Label>

                    <Col md={6}>
                      <Form.Check
                        type="radio"
                        name="isFullDisbursement"
                        id="is-fulldisbursement"
                        label="Yes"
                        onChange={() =>
                          setFieldValue("isFullDisbursement", true)
                        }
                        checked={values.isFullDisbursement}
                      />
                    </Col>

                    <Col md={3}>
                      <Form.Check
                        type="radio"
                        name="isFullDisbursement"
                        label="No"
                        id="not-fulldisbursement"
                        onChange={() =>
                          setFieldValue("isFullDisbursement", false)
                        }
                        checked={!values.isFullDisbursement}
                      />
                    </Col>
                  </Form.Group>
                </Form.Row>
                {values.isFullDisbursement && (
                  <Form.Row>
                    <Form.Group controlId="formCloseAccount">
                      <Col md={12}>
                        <Form.Check
                          type="checkbox"
                          name="closeAccount"
                          id="is-closeaccount"
                          label="Close Customer Account"
                          onChange={() => setFieldValue("closeAccount", true)}
                          checked={values.closeAccount}
                        />
                      </Col>
                    </Form.Group>
                  </Form.Row>
                )}
                {(!values.isFullDisbursement || values.closeAccount) && (
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="formBasicReason">
                      <Form.Label>Disbursement Reason</Form.Label>
                      <WithdrawalReasonSelect
                        value={values.reason}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.reason}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                )}
                {!values.isFullDisbursement && (
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="formAdminRole">
                      <Form.Label>Disbursement Amount</Form.Label>
                      <Form.Control
                        sm={4}
                        type="number"
                        name="amount"
                        value={values.amount}
                        onChange={(e) => {
                          const amount = e.target.value;
                          setFieldValue("amount", formatAmount(amount));
                        }}
                        onBlur={handleBlur}
                        isInvalid={touched.amount && !!errors.amount}
                        isValid={touched.amount && !errors.amount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.amount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                )}
                <Form.Group as={Col}>
                  {this.props.error && (
                    <Alert type="error" msg={this.props.error} />
                  )}

                  <div className="submit-row">
                    <span className="cancel-btn">
                      <Button
                        btnLabel="Cancel"
                        color="cancel"
                        name="cancel"
                        type="button"
                        onClick={this.props.onCancel}
                      />
                    </span>
                    <OverlayTrigger
                      rootClose
                      target={this._submitButton}
                      placement="bottom"
                      show={this.state.showApprovalPopover}
                      onToggle={(showApprovalPopover) =>
                        this.setState({ showApprovalPopover })
                      }
                      onExit={() =>
                        this.setState({ showApprovalPopover: false })
                      }
                      overlay={
                        <Popover className="action-popover">
                          <Popover.Content>
                            <p>
                              Are you sure you want to create a disbursement
                              request?
                            </p>
                            <div>
                              <Button
                                type="button"
                                color="cancel"
                                name="cancel"
                                btnLabel="Cancel"
                                onClick={() => document.body.click()}
                                size="sm"
                              />
                              <Button
                                type="button"
                                color="red"
                                name="submit"
                                btnLabel="Disburse"
                                withArrow={true}
                                loading={this.props.isUpdating}
                                onClick={() => this._disburseAccount(values)}
                                size="sm"
                              />
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button
                        color="action"
                        name="action"
                        btnLabel={"Disburse"}
                        size={"sm"}
                        ref={(ref) => (this._submitButton = ref)}
                      />
                    </OverlayTrigger>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const isUpdatingSelector = createLoadingSelector([
  adminConstants.DISPERSE_USER_ACCOUNT,
  adminConstants.LIQUIDATE_ACCOUNT,
]);

const errorSelector = createErrorSelector([
  adminConstants.DISPERSE_USER_ACCOUNT,
  adminConstants.LIQUIDATE_ACCOUNT,
]);

const mapStateToProps = (state) => {
  return {
    isUpdating: isUpdatingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  disburseUserAccount,
  liquidateAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(LiquidationForm));
