export const gustoId = 1;
export const quickbooksId = 2;
export const primaryPlanId = 3;
export const statePlanId = 4;
export const companionPlanId = 5;
export const virtualBankId = 6;
export const uniqueInviteCodesId = 7;
export const einVerificationId = 8;
export const automaticPayrollsId = 9;
export const useDbaId = 10;
export const daysBeforeDebitId = 11;
export const finchIntegrationId = 14;
export const disableReferralsId = 15;
export const viventiumPayrollIntegrationId = 16;
export const iSolvedPayrollIntegrationId = 18;
export const autoApproveJoinRequestsId = 19;
export const paylocityIntegrationId = 20;
export const useGroupNameId = 21;

export const payrollIntegrations180 = [finchIntegrationId];
export const payrollIntegrations360 = [
  gustoId,
  viventiumPayrollIntegrationId,
  iSolvedPayrollIntegrationId,
  paylocityIntegrationId,
];
export const allPayrollIntegrations = [
  ...payrollIntegrations180,
  ...payrollIntegrations360,
];

const features = {
  gusto: gustoId,
  quickbooks: quickbooksId,
  primaryPlan: primaryPlanId,
  statePlan: statePlanId,
  companionPlan: companionPlanId,
  virtualBank: virtualBankId,
  uniqueInviteCodes: uniqueInviteCodesId,
  einVerification: einVerificationId,
  automaticPayrolls: automaticPayrollsId,
  useDba: useDbaId,
  daysBeforeDebit: daysBeforeDebitId,
  finchIntegration: finchIntegrationId,
  disableReferrals: disableReferralsId,
  viventiumPayrollIntegration: viventiumPayrollIntegrationId,
  useGroupName: useGroupNameId,
};

export default features;
