import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { every, filter, includes, map, some, transform } from "lodash";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import Alert from "components/Alert";

import {
  allPayrollIntegrations,
  autoApproveJoinRequestsId,
  companionPlanId,
  daysBeforeDebitId,
  disableReferralsId,
  einVerificationId,
  finchIntegrationId,
  gustoId,
  iSolvedPayrollIntegrationId,
  paylocityIntegrationId,
  primaryPlanId,
  statePlanId,
  uniqueInviteCodesId,
  useDbaId,
  virtualBankId,
  viventiumPayrollIntegrationId,
  useGroupNameId,
} from "statics/featureIds";

const features = [
  {
    label: "Primary Plan",
    id: primaryPlanId,
  },
  {
    label: "Companion Plan",
    id: companionPlanId,
  },
  {
    label: "State Plan",
    id: statePlanId,
  },
  {
    label: "Allow option to expire group invites",
    id: uniqueInviteCodesId,
  },
  {
    label: "Fund via 3rd party ACH/WIRE",
    id: virtualBankId,
  },
  {
    label: "Validate employees using EIN",
    id: einVerificationId,
  },
  {
    label: "Allow option to choose group days before debit",
    id: daysBeforeDebitId,
  },
  {
    label: "Show group name in place of company name",
    id: useGroupNameId,
  },
  {
    label: "Show DBA in place of company name",
    id: useDbaId,
  },
  {
    label: "Disable Referrals",
    id: disableReferralsId,
  },
  {
    label: "Enable Finch for payroll roster syncing",
    id: finchIntegrationId,
  },
  {
    label: "Enable Gusto for payroll roster and deduction syncing",
    id: gustoId,
  },
  {
    label: "Enable Viventium for payroll roster and deduction syncing",
    id: viventiumPayrollIntegrationId,
  },
  {
    label: "Enable iSolved for payroll roster and deduction syncing",
    id: iSolvedPayrollIntegrationId,
  },
  {
    label: "Enable Auto-Approval of Employer Link Requests",
    id: autoApproveJoinRequestsId,
  },
  {
    label: "Enable Paylocity for payroll roster and deduction syncing",
    id: paylocityIntegrationId,
  },
];

export default class ProductFeatures extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    enabledProductFeatures: PropTypes.object,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      enabledProductFeatures: props.enabledProductFeatures,
    };
  }

  _handleProductFeatureChange = ({ target: { name, checked } }) => {
    const enabledProductFeatures = {
      ...this.state.enabledProductFeatures,
      [name]: checked,
    };

    // can only have one payroll integration enabled at a time
    const isPayrollIntegration = includes(allPayrollIntegrations, +name);
    if (isPayrollIntegration && checked) {
      const featuresToDisable = filter(
        allPayrollIntegrations,
        (id) => id !== +name
      );
      featuresToDisable.forEach((featureToDisable) => {
        enabledProductFeatures[featureToDisable] = false;
      });
    }

    this.setState({
      enabledProductFeatures,
    });
  };

  _updateProductFeatures = () => {
    const enabledFeatures = transform(
      this.state.enabledProductFeatures,
      (acc, val, key) => {
        if (val) {
          acc.push(+key);
        }
        return acc;
      },
      []
    );
    this.props.onChange(enabledFeatures);
  };

  _getFeatureCheckboxes = () => {
    return map(features, (feature) => {
      return (
        <Form.Check
          type="checkbox"
          id={feature.id}
          key={feature.id}
          checked={this.state.enabledProductFeatures[feature.id]}
          name={feature.id}
          disabled={false}
          label={feature.label}
          onChange={this._handleProductFeatureChange}
        />
      );
    });
  };

  render() {
    /* 
     btn should be disabled if no changes were made, can't directly match objects because
     when you click and unclick a check box it gets added to the state as false so have to 
     use custom logic to check for that by ignore false values if the feature flag wasn't already enabled
    */
    const btnDisabled = every(this.state.enabledProductFeatures, (val, key) => {
      if (this.props.enabledProductFeatures[key]) {
        return val === this.props.enabledProductFeatures[key];
      } else {
        return !val;
      }
    });

    const hasASelectedPlanType = some(
      this.state.enabledProductFeatures,
      (isChecked, key) => {
        if (
          key == primaryPlanId ||
          key == companionPlanId ||
          key == statePlanId
        ) {
          return isChecked;
        }
        return false;
      }
    );

    return (
      <div className="action-box">
        <Form.Group controlId="formProductFeatures">
          <div className="action-header">
            <span>
              <IconHeader variant="labelHeader" headerText="Product Features" />
              <IconSubheader subheader="Enable product features for a company" />
            </span>

            <div>
              <Button
                size="sm"
                disabled={btnDisabled || !hasASelectedPlanType}
                onClick={this._updateProductFeatures}
                btnLabel="Update"
                name="submit"
                withArrow
                loading={this.props.isFetching}
              />
            </div>
          </div>
          <div className="action-body">{this._getFeatureCheckboxes()}</div>
        </Form.Group>
        {this.props.error && <Alert msg={this.props.error} type="error" />}
      </div>
    );
  }
}
